<template>
	<div>
		<div class="cards">
			<AdminPendingCard
				v-for="transaction in transactions"
				:key="transaction._id"
				:transaction="transaction"
			/>
		</div>
	</div>
</template>

<script>
import AdminPendingCard from '@/views/admin/components/AdminPendingCard';

export default {
	name: 'AdminPendingPage',
	components: { AdminPendingCard },
	data: () => ({
		transactions: [],
	}),
	methods: {
		async fetchPending() {
			const result = await this.$store.dispatch('getPendingTransactions');
			result.clover?.forEach((cloverTransaction) => {
				cloverTransaction.type = 'CloverTransaction';
			});
			this.transactions = result.transaction.concat(result.clover);
			this.transactions.sort((a, b) => {
				return new Date(b.createdAt) - new Date(a.createdAt);
			});
		},
	},
	mounted() {
		this.fetchPending();
	},
};
</script>

<style scoped>
.cards {
	display: flex;
	flex-wrap: wrap;
}
</style>
